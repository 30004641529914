/* * {
    box-sizing: border-box
} */

#slides-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tabs-items-container {
    width: 100%;
}

.tabs-items-container ul {
    list-style: none;

    display: flex;
    justify-content: space-evenly;
}

.slideshow-container {
    margin: 72px 0;
}

.tabs-items-container ul,
.slideshow-container {
    padding: 0 260px;
}

#slides-container .tabs-items-container .tab-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px;

    width: 142px;
    height: 36px;

    color: #0F2539;
    font-weight: bold;
    border: 2px solid #0F2539;
    border-radius: 24px;
    opacity: 1;
}

.slide-show-tab {
    display: flex;
    align-items: center;
}

.slide-show-tab .img-container {
    position: relative;
    margin-right: 22px;
}

.slide-show-tab .img-container,
.text-content {
    flex: 1;
}

.text-content {}

.text-content h1 {
    color: #439FF4;
    text-transform: uppercase;
    font-size: 50px;
}

.text-content h2,
.text-content p {
    color: #0F2539;
}

.text-content p {
    font-size: 22px;
}

.slide-show-tab .img-container img {
    width: 80%;
    height: 95%;
}

.slide-show-tab .img-container img.img-over {
    width: 65%;
    height: 65%;

    top: 18%;
    left: 25%;

    position: absolute;
    border-radius: 22px;
}

.not-display {
    display: none;
}

/* Next & previous buttons */
/* .prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
} */

/* Position the "next button" to the right */
/* .next {
    right: 0;
    border-radius: 3px 0 0 3px;
} */

/* On hover, add a black background color with a little bit see-through */
/* .prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
} */

/* Caption text */
.text {
    /* color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center; */
}

/* Number text (1/3 etc) */
.number-text {
    /* color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0; */
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #FFFFFF;
    border: 1px solid #439FF4;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot.selected {
    background-color: #439FF4;
    border: 1px solid #D5D5D5;
}

.active,
.dot:hover {
    background-color: #dff9fb;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media screen and (max-width: 1620px) {

    .tabs-items-container ul,
    .slideshow-container {
        padding: 0 60px;
    }
}

@media screen and (max-width: 1070px) {
    .slide-show-tab .img-container img:first-child {
        display: none;
    }

    .slide-show-tab .img-container img.img-over {
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        position: relative;
    }

    .tabs-items-container ul,
    .slideshow-container {
        padding: 0;
    }
}

@media screen and (max-width: 1010px) {
    .slide-show-tab .img-container {
        height: 80%;
    }
}

@media screen and (max-width: 840px) {

    #slides-container .tabs-items-container .tab-item {
        padding: 12px;

        width: 90px;
    }

}

@media screen and (max-width: 690px) {
    .slide-show-tab {
        flex-direction: column;
    }
}