.top-nav,
.top-nav-mobile {
    padding: 56px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.top-nav .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-nav a,
.navbar-mobile a {
    float: left;
    display: block;
    color: #f2f2f2;
    /*text-align: center;
     margin: 0 30px; */
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.top-nav a {
    text-align: center;
    margin: 0 32px;
}

.top-nav a:hover {
    /* background-color: #ddd; 
    color: black;*/
}

/* .top-nav a.active {
    background-color: #04AA6D;
    color: white;
} */

/* .top-nav a.btn-bg,
.navbar-mobile a.btn-bg {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.top-nav a.btn-bg span,
.navbar-mobile a.btn-bg span {
    position: absolute;

    font-size: 40px;
} */

.top-nav-mobile {
    position: absolute;
    left: 0;
    top: 0;

    background-color: #0F2539;
    /* width: 300px; */
}

.navbar-mobile {
    /* padding: 52px 0; */
    /* height: 100vh; */

    display: flex;
    flex-direction: column;
}

.top-nav a.icon,
.navbar-mobile a.icon {
    margin-left: 22px;
}

.navbar-mobile a.icon {
    margin-top: 7px;
    margin-bottom: 62px;
    width: 60px;
}

a.mobile-menu-item {
    margin: 0 32px;
}

/* .navbar-mobile a.btn-bg,
.navbar-mobile a.btn-bg img {
    width: 180px;
} */

.top-nav .icon,
.not-display {
    display: none;
}

@media screen and (max-width: 1229px) {
    .top-nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1010px) {
    .top-nav {
        position: relative;
    }

    .navbar {
        position: absolute;
        left: 0;
    }

    .top-nav a:not(:first-child) {
        display: none;
    }

    .top-nav a.icon {
        display: block;
    }
}

@media screen and (min-width: 1010px) {
    .top-nav-mobile {
        display: none;
    }
}