/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

#root-container,
.content-container {
  /* height: 100vh; */
}

#root-container {
  /* top: 0px;
  left: 0px;
  max-width: 1920px;
  max-height: 1082px; */
}

/* .content-container {
  margin: auto;
  width: 90%;
} */

#root-container header.top-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  transition: all 3s;
}

#root-container header.scrolled {
  background-color: #0F2539;
}

main section {
  padding: 0 5%;
}

/* main section p {
  font-size: 28px;
} */

/*** SECTION 1 ***/
main section#section1 {
  height: 1082px;
  /* padding: 0 28px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent url('./assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* border: 1px solid #707070; */
  opacity: 1;
}

main section#section1 h1 {
  margin-top: 273px;


  text-align: center;
  color: #FFF;
}

main section#section1 h1 span {
  color: #439FF4
}

/*** SECTION 2 ***/
main section#section2 {
  padding: 148px 178px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #0F2539;
  color: #FFF;
}

main section#section2 div {
  margin-right: 72px;
}

main section#section2 h1 {
  color: #439FF4;
}

main section#section2 p {
  font-size: 24px;
}

main section#section2 img {
  max-width: 50%;
}

/*** SECTION 3 ***/
main section#section3 {
  padding: 72px 22px;

  background-color: #FFF;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main section#section3 h1 {
  margin-bottom: 72px;
}

main section#section3 h1.title1 {
  text-align: center;
}

main section#section3 h1 span {
  color: #439FF4;
}


/*** SECTION 4 ***/
main section#section4 {
  padding: 72px 22px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent url('./assets/images/section4-bg-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* border: 1px solid #707070; */
  opacity: 1;

  color: #FFF;
}

main section#section4 h1 {
  text-align: center;
}

main section#section4 .images-container {
  text-align: center;

  margin: 52px 0;
}

main section#section4 .images-container img {
  margin: 12px;
}

/*** SECTION 5 ***/
main section#section5 {
  padding: 72px 22px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main section#section5 h1 {
  color: #439FF4;
  text-align: center;
}

/*** SECTION 6 ***/
main section#section6 {
  padding-top: 52px;
  padding-bottom: 52px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent url('./assets/images/section6-bg-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* border: 1px solid #707070; */
  opacity: 1;

  color: #FFF;
}

main section#section6 h1 {
  text-align: center;
}

main section#section6 h2 {
  font-family: "Roboto", sans-serif;
}

main section#section6 p.bg-transparent {
  /* background-color: #0F2539;
  opacity: 0.5; */

  background: transparent url('./assets/images/transparent-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  text-align: center;

  padding: 52px 62px;
  border-radius: 12px;

  max-width: 890px;
  font-size: 22px;
}

main section#section6 .cards-container {
  display: flex;
}

main section#section6 .card {
  background-color: #FFF;
  border-radius: 22px;

  text-align: center;
  margin: 12px;
  padding: 22px;

  max-width: 350px;
  min-height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main section#section6 .card h2 {
  color: #439FF4;
}

main section#section6 .card p {
  color: #0F2539;
  font-size: 22px;
}

/* main section#section6 .card a.km-btn {
  margin-bottom: 0;
} */

/*** SECTION 7 ***/
main section#section7 {
  background-color: #439FF4;
  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}

main section#section7 .left {
  padding-left: 100px;
  padding-bottom: 72px;
  flex: 1;
}

main section#section7 .left h1 {
  margin-bottom: 72px;
}

main section#section7 .left a {
  font-family: "Bebas Neue", sans-serif;
  font-size: 42px;
}

main section#section7 .right {
  flex: 2;
  max-width: 805px;
}

main section#section7 .right img {
  display: block;
  width: 100%;
}

main section#section7 a {
  padding: 16px 52px;

  text-decoration: none;
  border: 2px solid #FFF;
  border-radius: 12px;

  background-color: #0F2539;
  color: #FFF;
  font-size: 28px;
  font-weight: bold;
}

footer #section8 {
  padding: 52px;
  background-color: #0F2539;
  color: #FFF;

  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
}

footer #section8 .logo-blue-container {
  align-self: center;
}

footer #section8 nav.nav-bar {
  display: flex;
  flex-direction: column;
}

footer #section8 .nav-bar a {
  color: #FFF;
  margin: 11px 0;

  text-decoration: none;
}

footer #section8 nav.social-media-nav h4,
footer #section8 div.payment-methods h4 {
  margin-top: 11px;
}

footer #section8 nav.social-media-nav div a {
  color: #439FF4;
  text-decoration: none;
  margin-right: 22px;

  font-size: 28px;
}

footer #section8 nav.social-media-nav .form {
  position: relative;
}

footer #section8 nav.social-media-nav .form input {
  width: 190px;
  padding-right: 48px;
  font-size: 16px;
}

footer #section8 nav.social-media-nav .form a {
  font-size: 18px;
  position: absolute;
  top: 2px;
  right: -5px;

  width: 20px;
  height: 20px;

  background-color: yellow;
  
}

footer #section8 nav.social-media-nav .form a img {
  width: 30px;
  height: 34px;
}

footer #section8 nav.social-media-nav div {
  margin-bottom: 52px;
}

footer #section8 nav.social-media-nav input {
  padding: 8px;
}

footer #section8 div.payment-methods h4 {
  color: #439FF4;
}

footer #section8 div.payment-methods ul {
  list-style: none;
  padding-left: 0;
}

footer #section8 div.payment-methods ul li {
  margin-bottom: 22px;
}

footer #copyright {
  padding: 22px;

  text-align: center;

  background-color: #1764C0;
  color: #17161B;
  font-size: 16px;
}

@media screen and (max-width: 1319px) {

  /* .content-container {
    width: 100%;
  } */
  main section#section3 h1 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1229px) {
  main section#section2 {
    padding: 0 78px;
  }
}

@media screen and (max-width: 1470px) {

  /*** SECTION 7 ***/
  main section#section7 {
    flex-direction: column;
    align-items: center;
  }

  main section#section7 .left {
    padding-left: 0;
    padding-bottom: 72px;
  }

  main section#section7 .right {
    flex: 1;
  }

  main section#section7 .left {
    text-align: center;
  }

}

@media screen and (max-width: 1010px) {

  /*** SECTION 2 ***/
  main section#section2 {
    display: flex;
    flex-direction: column;

    padding: 52px;
  }

  main section#section2 div {
    margin-right: 0;
    text-align: center;
  }

  main section#section2 img {
    margin-top: 22px;
    max-width: 100%;
  }

  /*** SECTION 6 ***/
  main section#section6 .cards-container {
    flex-direction: column;
  }


  /*** SECTION 8 ***/
  footer #section8 {
    flex-direction: column;
    align-items: center;
  }

  /* footer #section8 .logo-blue-container, */
  footer #section8 nav.nav-bar,
  footer #section8 nav.social-media-nav,
  footer #section8 div.payment-methods {
    margin-top: 32px;
    /* width: 50%; */
  }

}